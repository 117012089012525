<script lang="ts" setup>
import { useGroups } from '@reports/components/TransferList/composables/useGroups'
import { useTransferList } from '@reports/components/TransferList/composables/useTransferList'
import type { ReportFieldGroup, ReportTemplateFieldDefinition } from '@reports'

interface Props {
  modelValue?: ReportTemplateFieldDefinition[]
  groups: ReportFieldGroup[]
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  fields: () => [],
})

const emit = defineEmits(['update:modelValue'])
const modelValue = useVModel(props, 'modelValue', emit)

const groups = computed(() => props.groups)

/* === GROUPS === */
const {
  options: groupOptions,
  active: activeGroup,
  select: selectGroup,
} = useGroups(groups)
const _activeGroupOptions = computed(() =>
  activeGroup.value
    ? [
        {
          label: activeGroup.value.name,
          value: activeGroup.value.id,
        },
      ]
    : [],
)
/* === GROUPS === */

/* === TRANSFER LIST === */
const {
  selectedOptions: selectedFieldOptions,
  unselectedOptions: unselectedFieldOptions,
  selections,
  select: selectField,
  selectAll: selectAllFields,
  unselect: unselectField,
} = useTransferList(groups, activeGroup, modelValue)
/* === TRANSFER LIST === */
</script>

<template>
  <div class="flex">
    <ReportsTransferListGroups
      class="h-[500px] w-[290px]"
      :options="groupOptions"
      :selected="_activeGroupOptions"
      @select="selectGroup"
    />

    <ReportsTransferListFields
      class="h-[500px] w-[390px]"
      :group="activeGroup"
      :unselected-options="unselectedFieldOptions"
      :selected-options="selectedFieldOptions"
      @select="selectField"
      @unselect="unselectField"
    />

    <ReportsTransferListSelections
      v-model:options="selections"
      class="h-[500px] w-[320px]"
      @unselect="unselectField"
    />
  </div>
</template>
